import React from "react"
function Index() {

	return (
		<div className="privacy">
	  <div className="container">
      <h1>오로라브라우저 서비스 이용약관</h1>
    <div class="article">
        <h2>제1장 총칙</h2>
        <h2>제1조 (목적)</h2>
        <p>이 약관은 오로라브라우저(이하: 회사)가 제공하는 관련 제반 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 회원 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>

        <h2>제2조 (약관의 명시, 설명과 개정)</h2>
        <p>① 이 약관의 내용은 회사의 서비스 회원가입 관련 사이트에 게시하거나 기타의 방법으로 이용자에게 공지하고, 이용자가 회원으로 가입하면서 이 약관에 동의함으로써 효력을 발생합니다.</p>
        <p>② 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
        <p>③ 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 회원에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 회원에게 알립니다.</p>
        <p>④ 회사가 전항에 따라 개정약관을 공지하면서 회원에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</p>
        <p>⑤ 회원이 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</p>

        <h2>제3조 (약관 외 준칙)</h2>
        <p>회사는 서비스에 대하여 이 약관 외에 별도의 공지사항, 개별 약관, 개별 동의 사항, 이용안내, 운영정책, 세부지침 등(이하 통틀어 “세부지침”이라 합니다)을 정하여 이를 회원에게 알리거나 동의를 받을 수 있으며, 세부지침 중 회원의 동의를 받은 내용이 이 약관과 상충할 경우에는 “세부지침”이 우선하여 적용됩니다. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “세부지침” 및 관련 법령 또는 상관례에 따릅니다.</p>

        <h2>제4조 (용어의 정의)</h2>
        <p>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <p>1. 회원: 이 약관을 승인하고 회원가입을 하여 회사와 서비스 이용계약을 체결한 자를 말합니다.</p>
        <p>2. 아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 등록하는 전자우편 주소 또는 휴대전화 번호를 말합니다.</p>
        <p>3. 비밀번호: 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 회사가 승인한 문자와 숫자의 조합대로 설정한 것을 말합니다.</p>
    </div>

    <div class="article">
        <h2>제2장 서비스 이용 신청 및 승낙 (회원가입 및 탈퇴)</h2>
        <h2>제5조 (이용계약의 성립)</h2>
        <p>① 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p>
        <p>② 회원가입은 회사의 승낙이 회원에게 도달한 시점으로 합니다.</p>
        <p>③ 이용 계약은 회원ID 단위로 체결합니다. 이용계약이 성립되면, 이용신청자는 회원으로 등록됩니다.</p>
        <p>④ 실명이 아니거나 타인의 이름, 전화번호 등의 개인정보를 도용하여 허위 가입한 회원은 법적인 보호를 받을 수 없으며, 이에 따른 민사, 형사상의 모든 책임은 가입한 회원이 져야 합니다.</p>
        <p>⑤ 19세이상 성인인증이 필요한 경우 본인인증을 거쳐야 서비스를 이용할 수 있습니다.</p>
        <p>⑥ 이용자가 국제자금세탁방지기구(FATF, Financial Action Task Force)에서 자금세탁방지 요주의 국가로 지정한 국가 또는 지역의 국민, 시민권자, 영주권자, 거주자 등인 경우 서비스 이용이 제한될 수 있으며, 회원은 다음 각 호를 보증합니다.</p>
        <p>1. 요주의 국가의 국민, 시민권자, 영주권자, 거주자 등이 아닐 것</p>
        <p>2. 요주의 국가 체류 중에 서비스를 이용하지 않을 것</p>
        <p>⑦ 제1항에 따른 신청에 있어 회사는 필요 시 관계 법령에 의하여 이용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. 만일, 이러한 회사의 제공 요청을 거부하여 이용자 본인임이 확인되지 않아 발생하게 되는 불이익에 대하여 회사는 책임을 지지 않습니다.</p>

        <h2>제6조 (이용신청)</h2>
        <p>① 이용신청은 온라인으로 회사 소정의 가입신청 양식에서 요구하는 사항을 기록하여 신청합니다.</p>
        <p>② 온라인 가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며, 서비스 사용의 제한을 받을 수 있습니다.</p>
        <p>③ 사실과 다른 정보, 거짓 정보를 기입하거나 추후 그러한 정보임이 밝혀질 경우 회사는 서비스 이용을 일시 정지하거나 영구정지 및 이용 계약을 해지할 수 있습니다. 이로 인하여 회사 또는 제3자에게 발생한 손해는 해당 회원이 모든 책임을 집니다.</p>

        <h2>제7조 (회원정보 사용에 대한 동의 및 이용신청의 승낙)</h2>
        <p>① 회원정보 사용에 대한 동의</p>
        <p>1. 회사는 회원의 개인정보를 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.</p>
        <p>2. 회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 회원 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 회원 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 회원 정보의 내용 등을 사전에 공지하고 회원의 동의를 얻어야 합니다.</p>
        <p>3. 회원은 회원정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.</p>
        <p>4. 회원이 이용신청서에 회원정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.</p>
        <p>② 이용신청의 승낙</p>
        <p>1. 회사는 회원이 회사 소정의 가입신청 양식에서 요구하는 모든 사항을 정확히 기재하여 이용신청을 한 경우 회원가입을 승낙할 수 있습니다.</p>
        <p>2. 회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 하지 않을 수 있습니다.</p>
        <p>1) 본인 실명이 아니거나 타인의 명의를 이용한 경우</p>
        <p>2) 이용계약 신청서의 내용을 허위로 기재한 경우</p>
        <p>3) 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</p>
        <p>4) 부정한 용도로 본 서비스를 이용하고자 하는 경우</p>
        <p>5) 본 서비스와 경쟁 관계에 있는 이용자가 신청하는 경우</p>
        <p>6) 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
        <p>3. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 승낙을 유보할 수 있습니다.</p>
        <p>③ 회원은 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</p>
        <ol>
        <li>
            <ol type="a">
                <li>설비에 여유가 없는 경우</li>
                <li>기술상 지장이 있는 경우</li>
                <li>기타 회사의 사정상 이용승낙이 곤란한 경우</li>
            </ol>
        </li>
        <li>회사는 다음 각 호에 해당하는 이용신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.
            <ol type="a">
                <li>이름이 실명이 아닌 경우</li>
                <li>다른 사람의 명의를 사용하여 신청한 경우</li>
                <li>이용 신청 시 필요내용을 허위로 기재하여 신청한 경우</li>
                <li>사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
                <li>회사가 정한 이용신청요건에 미비한 부분이 있었을 때</li>
            </ol>
        </li>
    </ol>

    <h2>제8조 (이용계약의 중지 및 해지)</h2>
    <ol>
        <li>이용계약은 회원 또는 회사의 해지에 의해 종료됩니다.
            <ol>
                <li>회원이 이용계약을 해지하고자 할 때에는 자신의 자산을 전부를 외부로 이전한 후 회사에 해지 신청을 하여야 합니다.</li>
                <li>회사는 회원이 아래의 사유에 해당하는 경우 상당한 기간을 정하여 최고 후 서비스 이용을 제한하거나 이용계약을 해지할 수 있습니다.
                    <ul>
                        <li>타인의 서비스 ID 및 비밀번호를 도용한 경우</li>
                        <li>서비스 운영을 고의로 방해한 경우</li>
                        <li>가입한 이름이 실명이 아닌 경우</li>
                        <li>회원이 사회적 공익을 저해할 목적으로 서비스를 이용하는 경우</li>
                        <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
                        <li>서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우</li>
                        <li>해킹 또는 당사에서 인가하지 않은 프로그램을 사용 및 불법적인 거래로 이득을 취하였다고 의심되는 경우</li>
                        <li>정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                        <li>타인의 개인정보, 이용자ID 및 비밀번호로 부정하게 사용하는 경우</li>
                        <li>회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</li>
                        <li>같은 사용자가 다른 ID로 이중등록을 한 경우</li>
                        <li>회사, 다른 회원 또는 제3자의 지식재산권을 침해하는 경우</li>
                        <li>방송통신심의위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우</li>
                        <li>이용자에 대한 개인정보를 그 동의 없이, 수집, 저장, 공개하는 경우</li>
                        <li>회원의 서비스 이용이 범죄와 결부된다고 객관적으로 판단되는 경우</li>
                        <li>수수료 등 이익을 얻을 목적으로 타인을 대행하여 투자상품 거래 하는 경우</li>
                        <li>회원의 행위가 기타 관계 법령에 위배되는 경우</li>
                        <li>위장 주문 등을 비롯하여 시장 조작 행위에 가담한 경우</li>
                        <li>회원이 당사에 납부할 의무가 있는 금원 등을 체불하고 있는 경우</li>
                    </ul>
                </li>
            </ol>
        </li>
        <li>서비스 이용 중지 또는 제한 절차
            <ol>
                <li>회사는 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 서면(전자우편 포함) 또는 전화, 홈페이지의 메시지 기능 등의 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다.</li>
                <li>다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 전항의 과정 없이 서비스 이용을 제한할 수 있습니다.</li>
                <li>서비스 이용중지를 통지 받은 회원 또는 그 대리인은 이용중지에 대하여 이의가 있을 경우 이의 신청을 할 수 있습니다.</li>
                <li>회사는 이용중지 기간 중에 그 이용중지 사유가 해소된 것이 확인된 경우에 한하여 이용중지 조치를 즉시 해제합니다.</li>
            </ol>
        </li>
        <li>이용계약 해지
            <ol>
                <li>회사가 서비스 이용을 중지 또는 제한 시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 이용계약을 해지할 수 있습니다.</li>
                <li>회사가 이용계약을 해지하는 경우에는 회원등록을 말소합니다. 회사는 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</li>
            </ol>
        </li>
    </ol>
    <h2>제9조 (회원정보의 변경)</h2>
<ol>
  <li>
    <p>① 회원은 개인정보 수정화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 생년월일, 성별, 아이디 등은 수정이 불가능합니다.</p>
  </li>
  <li>
    <p>② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</p>
  </li>
  <li>
    <p>③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>
  </li>
</ol>
<h2>제10조 (회원 아이디와 비밀번호 관리에 대한 회원의 의무)</h2>
<ol>
  <li>
    <p>① 아이디와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실로 인한 경우에는 그러하지 아니합니다.</p>
  </li>
  <li>
    <p>② 회원은 자신의 아이디가 부정하게 사용된 사실을 알게 될 경우 반드시 회사에 그 사실을 통지하고 회사의 안내에 따라야 합니다.</p>
  </li>
  <li>
    <p>③ 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>
  </li>
</ol>
<h2>제11조 (정보의 제공)</h2>
<p>회사는 유·무선전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 회원은 언제든지 수신을 거부할 수 있습니다.</p>
<ul>
  <li>이벤트 및 행사관련 등의 서비스</li>
  <li>기타 회사가 수시로 결정하여 회원에게 제공하는 서비스</li>
</ul>
<h2>제12조 (서비스의 종류)</h2>
<ol>
  <li>
    <p>① 회사에서 제공하는 서비스에는 파생상품 거래 (판매관련, 구매관련, 거래API제공, 시세 정보검색 관련 서비스) 서비스, 컨텐츠 서비스 등이 있습니다.</p>
  </li>
  <li>
    <p>② 회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.</p>
  </li>
  <li>
    <p>③ 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 서비스, 포인트 등을 이용할 수 있는 이용권한만을 부여하며, 회원은 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.</p>
  </li>
</ol>
<h2>제13조 (서비스 내용의 공지 및 변경)</h2>
<ol>
  <li>
    <p>① 회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 서비스 화면을 통하여 공지하며, 회원은 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.</p>
  </li>
  <li>
    <p>② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.</p>
  </li>
</ol>
<h2>제14조 (서비스의 유지 및 중지)</h2>
<ol>
  <li>
    <p>① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전에 고지하여 정한 날이나 시간은 그러하지 않습니다.</p>
  </li>
  <li>
    <p>② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.</p>
  </li>
  <li>
    <p>③ 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.</p>
    <ol type="1">
      <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
      <li>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
      <li>회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우</li>
      <li>기타 불가항력적 사유가 있는 경우</li>
    </ol>
  </li>
  <li>
    <p>④ 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한할 수 있습니다. 이 경우 회사는 제한사항 및 사유를 사전에 공지하거나 사후에 통지할 수 있습니다.</p>
  </li>
  <li>
    <p>⑤ 회사는 서비스 제공의 일부 또는 전부를 중단할 경우에는 최소 30일 전에 해당 내용을 고지합니다. 다만, 회사가 통제할 수 없는 사유로 인한 서비스 중단의 경우에는 사전 고지 없이 중단할 수 있습니다.</p>
  </li>
</ol>
<h2>제15조 (회원의 거래 이용 제한)</h2>
    <ol>
        <li>회사는 다음 각 호에 해당하는 경우 회원의 거래 이용을 제한할 수 있습니다.</li>
        <ol type="1">
            <li>자금의 출처가 불법적인 것으로 확인되는 경우</li>
            <li>시장 조작 행위가 확인되는 경우</li>
            <li>결제서비스의 제공사 및 발행사의 요청이 있는 경우</li>
        </ol>
        <li>위의 경우에 해당하는 경우 회사는 해당 내용을 회원에게 홈페이지 공지 등의 방법을 통해 알립니다.</li>
        <li>제한사유가 중복발생 시에는 모든 제한해지조건을 갖추었을 경우에 한해 해지 처리할 수 있습니다.</li>
    </ol>

    <h2>제16조 (회원의 입금 및 출금 이용제한)</h2>
    <ol>
        <li>회사는 다음 각 호에 해당하는 경우 회원의 입금 및 출금 이용을 제한하거나 지연하여 승인할 수 있습니다.</li>
        <ol type="1">
            <li>가입 회원명과 입금자명이 다르게 입금되었을 경우</li>
            <li>회사가 정한 서비스 이용권한의 범위를 벗어난 경우</li>
            <li>경찰, 국세청 등의 국가기관과 금융기관으로부터 회원의 계정이 불법행위에 이용된 것으로 의심된다는 정보의 제공이 있는 경우</li>
        </ol>
        <li>위의 경우에 해당하는 경우 회사는 해당 내용을 회원에게 홈페이지 공지 등의 방법을 통해 알립니다.</li>
        <li>이용제한 및 지연 사유가 중복 발생시에는 관리자 또는 운영자가 요구하는 해제조건을 갖추었을 경우에 한해 해제 처리할 수 있습니다.</li>
    </ol>

    <h2>제17조 (투자 거래 등)</h2>
    <ol>
        <li>회사가 제공하는 서비스를 통하여 투자상품을 투자 또는 철회하는 회원은 회사가 제공하는 등록 양식에 따라 거래를 등록하여야 합니다.</li>
    </ol>

    <h2>제18조 (거래 서비스 이용제한)</h2>
    <p>회사는 아래의 기준에 따라 회원의 서비스 이용을 제한할 수 있습니다.</p>
    <p>이 경우 회사는 그 사유, 일시 및 기간을 정하여 회원이 서비스 이용 제한에 대하여 통지일로부터 7일간 이의를 신청할 수 있습니다.</p>
    <ul>
        <li>서비스 제한(로그인 외 서비스이용 불가)</li>
        <li>로그인 제한</li>
        <li>일부 서비스 제한(매수, 매도, 충전, 환급 제한)</li>
    </ul>

    <h2>제19조 (투자 거래 관련 서비스 수수료의 내용)</h2>
    <ol>
        <li>회사는 회원에게 인터넷을 통한 서비스를 제공하는 대가로 수수료를 부과합니다.</li>
        <li>수수료는 회사의 홈페이지 내 사용 안내 및 운영 규칙, 이용 안내 등에 명시되어 있으며, 회사 및 시장의 상황에 따라 변경될 수 있습니다.</li>
        <li>회사는 새로운 서비스 교체 또는 기타 회사가 서비스를 제공할 수 없는 사유 발생시 제공되는 서비스를 중단할 수 있습니다.</li>
    </ol>

    <h2>제20조 (개인정보의 보호)</h2>
    <ol>
        <li>회원의 개인 정보에 대해서는 회사의 “개인정보처리방침”이 적용됩니다.</li>
        <li>회원이 이용 신청서에 회원정보를 기재하고, 회사에 본 약관에 따라 이용 신청 하는 것은 회사가 본 약관 및 “개인정보취급방침”에 따라 이용
            신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.</li>
    </ol>
    <div class="article">
    <h2>제21조 (회사의 면책사항 및 손해배상)</h2>
    <ol>
      <li>회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지
        아니한 투자금액의 가치를 보증하지 않습니다.</li>
      <li>회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로
        서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.</li>
      <li>회사는 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에
        책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</li>
      <li>회사는 회원의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 회원에게 정당한 사유가 있는 경우에는 그러하지
        아니합니다.</li>
      <li>회사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 과실이 있는
        경우에는 그러하지 아니합니다.</li>
      <li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없거나 회사의 고의 또는 과실이 없는 한 책임을 지지 않습니다.</li>
      <li>회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다. 만약 서버 점검 완료 후 국내외 거래소의
        서비스상의 구매 / 시세가 비정상적으로 차이가 날 경우, 회사는 회원을 보호하기 위하여 내부 정책에 따라 대기 주문 건을 취소할 수 있습니다.</li>
      <li>누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 투자금액을 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전
        통지 후 해당 투자금을 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.</li>
      <li>회원이 회사에게 손해배상을 청구할 경우 회사는 회원의 투자금액에 대하여 동결 할 수 있음</li>
      <li>회원이 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을지지 않습니다.</li>
      <li>회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을지지 않습니다.</li>
      <li>회사는 회원이 아이디 및 비밀번호 등을 관리하지 않아 발생하는 제3자 서비스 이용 및 결제에 대해 책임을지지 않습니다. 다만, 회사의 고의 또는
        과실에 의한 경우에는 그러하지 아니합니다.</li>
    </ol>
  </div>

  <div class="article">
    <h2>제22조 (대리 및 보증의 부인)</h2>
    <ol>
      <li>회사는 투자금을 양도하거나 이동하고자 하는 회원을 대리할 권한을 갖고 있지 않으며, 회사의 어떠한 행위도 판매자 또는 구매자의 대리 행위로
        간주되지 않습니다.</li>
      <li>회사는 회사가 제공하는 서비스를 통하여 이루어지는 회원 간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의 사실 및 진위, 적법성에 대하여
        보증하지 않습니다.</li>
      <li>회사에 연결(링크)된 사이트(상품권몰, 유료컨텐츠 제공업체 등)는 회사와 계약을 통하여 제휴관계를 맺은 업체들로서 회사는 단지 제휴업체와의 연결
        (링크)만을 제공할 뿐이며, 제휴업체의 서비스 운영 및 관리, 물품의 판매, 배송 및 환불 등에 대한 모든 책임은 회사에 고의 또는 과실이 없는 한
        제휴업체가 부담하고 회사는 일절 관여하지 않습니다.</li>
    </ol>
  </div>

  <div class="article">
    <h2>제23조 (관할법원 및 준거법)</h2>
    <ol>
      <li>본 서비스 이용 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 회원 간에 발생한 분쟁으로
        소송이 제기되는 경우 대한민국 법률이 적용됩니다.</li>
      <li>서비스 이용과 관련하여 회사와 회원 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</li>
    </ol>
    </div>

<div class="article">
  <h2>제24조 (기타)</h2>
  <ol>
    <li>당사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 당사에 귀속됩니다.</li>
    <li>회원이 서비스 내에 게시한 게시물의 모든 권리 및 책임은 이를 게시한 회원에게 있습니다. 다만, 회원이 당사에 제공한 공개적인 게시물의 경우
      회사가 복제, 수정, 전시, 배포, 출판 및 2차 저작물과 편집저작물의 작성 등을 할 수 있도록 사용료 없는 비 배타적인 사용권을 부여한 것에 상호
      합의한 것으로 봅니다.</li>
  </ol>
</div>

<div class="article">
  <h2>부칙</h2>
  <ol>
    <li>이 약관은 2024년 6월 4일부터 적용됩니다.</li>
  </ol>
</div>


    </div>
        </div>
		</div>
	)
}

export default Index