import React from 'react'

import Main from 'pages/Index'
import Terms from 'pages/terms'
import PrivacyPolicy from 'pages/privacypolicy'


const publicRoutes = [
	{ path: "/", component: <Main /> },
	{ path: "/terms", component: <Terms /> },
	{ path: "/privacy", component: <PrivacyPolicy /> },
]

export { publicRoutes }