import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Index from "./routes/index"
import Header from "layout/Header";
import Footer from "layout/Footer";

import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/mobile.css";

function App() {

  return (
    <>
      <div className="content-wrapper">
        <Header />
        <Router>
				<Index />
			</Router>
        <Footer />
      </div>
    </>
  );
}

export default App;
