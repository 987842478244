import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import { publicRoutes, authRoutes } from './routes'

import NoAuthLayout from '../layout/NoAuthLayout';

function createPublicRoutes() {
	return publicRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}


const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>


			<Route
				path="*"
				element={<Navigate to="/" replace={true} />}
			/>
		</Routes>
	)
}

export default Index;