import React from "react";

import logo from "assets/images/logo1.svg";
import bottom_footer from "assets/images/bottom_footer.png";

import footer_01 from "assets/images/googleplay.png";
import footer_02 from "assets/images/windows.png";
import footer_03 from "assets/images/apple.jpeg";
import bottom_footer_01 from "assets/images/bottom_footer_01.svg";
import bottom_footer_02 from "assets/images/bottom_footer_02.svg";
import bottom_footer_03 from "assets/images/bottom_footer_03.svg";

function Footer() {

  function hendleClick(){
    alert('Coming soon!');
  }
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <ul>
                <li className="footer_title">About us</li>
                <li>Meet the Team</li>
                <li>Our Story</li>
                <li>Career</li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul>
                <li className="footer_title">Community</li>
                <li>
                  <a href="https://www.facebook.com/people/Orora-browser/61558532393945/?mibextid=kFxxJD" target="_blank">
                    <img src={bottom_footer_03} alt="" /> Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/ororabrowser/?igsh=MWw4dWhzbTgwdW5jbw%3D%3D&utm_source=qr" target="_blank">
                    <img src={bottom_footer_02} alt="" /> Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@ororabrowser" target="_blank">
                    <img src={bottom_footer_01} alt="" /> Youtube
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul className="download_footer">
                <li className="footer_title download_text_footer">Download</li>
                <li>
                  <img src={footer_01} alt="" />
                </li>
                <li>
                  <img src={footer_02} alt="" />
                </li>
                <li onClick={hendleClick}>
                  <img src={footer_03} alt="" />
                </li>
              </ul>
            </div>

            <div className="col-md-6 footer_logo_area">
              <div className="mb-3">
                <img src={logo} alt="" width={250} />
              </div>
              <div className="color-grey footer_grey_text">
              orora! 광고는 사라지고 풍부한 비디오  <br />
원활한 브라우징을 위한 관문

              </div>
              <div className="footer_center">
                <button className="main-btn">광고제휴 문의</button>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer container">
        <div className="row w-100">
          <div className="col-md-4">
            <img src={bottom_footer} alt="" className="bottom_footer" />
          </div>
          <div className="col-md-4 text-center">
            <div className="copyright">©Orora 2024, All Rights Reserved</div>
          </div>
          <div className="col-md-4 text-end">
           <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
